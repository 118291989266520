.card {
  border-radius: 0.5rem;
  box-shadow: 0 1px 1px #0003;
  color: #fff;
  min-width: 0;
  padding: 24px;
  text-decoration: none;
  transition: all 0.1s ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background-color: #171717;

  margin: 1rem 0;
}

.card:hover {
  box-shadow: 0 3px 5px #00000080;
  transform: scale(1.01);
}

@media only screen and (min-width: 500px) {
  .card {
    padding: 50px;
  }
}

.link {
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: -0.02em;

  margin-bottom: 1.5rem;
}

.content {
  font-size: 1.125rem;
  line-height: 36px;
  letter-spacing: 0.02em;
}
