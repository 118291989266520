.iframe {
  width: 100%;
  min-height: fit-content;
  border: none;
  overflow: clip;

  border: 1px solid #ccc;
  border-radius: 0.3em;

  padding: 1rem;
  margin: 0.5rem 0;
}
