.container {
  min-height: 100vh;
  margin: 0 auto;
  max-width: 1024px;
  padding: 32px;
}

.containerSecondary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.containerSecondary > img {
  max-width: 60%;
}

@media only screen and (min-width: 768px) {
  .container {
    padding-left: 90px;
    padding-right: 90px;
  }

  .containerSecondary {
    flex-direction: row;
  }
}
