.control {
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  height: 50px;
  padding: 0 40px;
  border-radius: 5px;
  border: none;
  background-color: #000;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  margin: 0.5rem 0;
}

.button:hover {
  transform: scale(1.01);
}

.button:active {
  transform: scale(0.97);
}
