@import "./reset.css";

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./fonts/jost-v12-latin-regular.woff2") format("woff2"),
    url("./fonts/jost-v12-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./fonts/jost-v12-latin-700.woff2") format("woff2"),
    url("./fonts/jost-v12-latin-700.woff") format("woff");
}

@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 900;
  src: local(""), url("./fonts/jost-v12-latin-900italic.woff2") format("woff2"),
    url("./fonts/jost-v12-latin-900italic.woff") format("woff");
}

*,
*::before,
*::after {
  font-family: "Jost", sans-serif;
}

pre * {
  font-family: "Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono",
    monospace;
}

p {
  font-size: 1.2rem;
}
