#second-example {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#second-example > img {
  width: 50%;
}

#second-example > span {
  text-align: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  #second-example {
    flex-direction: column;
  }

  #second-example > img {
    width: 100%;
  }
}
