.container {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  cursor: zoom-out;
  z-index: 16777271;
  visibility: visible;
}

.background {
  z-index: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  user-select: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  transition-property: opacity;
  transition-timing-function: ease;
  transition-duration: 375ms;
}

.image {
  top: 50%;
  left: 50%;
  width: 95%;
  height: 95%;
  opacity: 1;
  z-index: 16777271;
  user-select: none;
  position: absolute;
  pointer-events: none;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: 500ms;
  transform: translate(-50%, -50%) scale(1);
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 2px 16px);
}
