.title {
  font-family: "Jost", sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 2.5rem;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #111;
  margin-bottom: 1rem;
}

.subtitle {
  font-family: "Jost", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #111;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
